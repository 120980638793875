import React, {useState} from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import {Grid, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import FormTextField from "./FormTextField";
import {NavigateNextOutlined, SendOutlined} from "@mui/icons-material";
import InArticle from "../adsense/InArticle";

interface FormValues {
    message: string;
}

interface Props {
    onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void
    comp: React.ReactNode
}

const MessageForm: React.FC<Props> = ({onSubmit, comp, ...props}) => {

    const [message, setMessage] = useState('')

    const validationSchema = yup.object().shape({
        message: yup
            .string()
            .max(1000, 'Maximum 1000 characters')
            .required("Required")
    })

    const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
        const val = (e.target as HTMLInputElement).value
        setMessage(val)
    }

    return(
        <Grid container item justifyContent={"center"} alignItems={"center"} spacing={2} xs={12}>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        message: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formikProps: FormikProps<FormValues>) => (
                        <Form
                            noValidate
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        >
                            <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                <Grid container item xs={12}>
                                    <Field
                                        name="message"
                                        label="Message"
                                        component={FormTextField}
                                        multiline
                                        fullWidth
                                        rows={4}
                                        variant="outlined"
                                        inputProps={{maxLength: 1000}}
                                        sx={{
                                            backgroundColor: '#f8f8f8',
                                        }}
                                        placeholder={"Type your message here"}
                                    />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8} alignItems={"center"}>
                                        <Typography variant={"subtitle2"} color={"textPrimary"} style={{fontSize: "smaller"}} align={"left"}>Message Responsibly!</Typography>
                                    </Grid>
                                    <Grid item xs={4} alignItems={"center"}>
                                        <Typography style={{fontSize: "smaller"}} align='right' variant={"subtitle2"}>{message.length}/1000</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {comp}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} alignItems="center" justifyContent='center' marginTop={4}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color={"primary"}
                                        loading={formikProps.isSubmitting}
                                        endIcon={<SendOutlined />}
                                        loadingPosition="end"
                                    >
                                        Send
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    )
}

export default MessageForm