import React from "react";
import {Box, Grid, Link} from "@mui/material";
import {PLAY_STORE_LINK} from "../codelibrary/utility/constants";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    footer: {
        color: theme.palette.text.primary,
        '& a': {
            color: '#606060',
        }
    },
    copyRight: {
        '& a': {
            color: theme.palette.primary.main,
        },
    },
}))

interface Props {
    quickLinks?: boolean
}

const Footer: React.FC<Props> = ({quickLinks}) => {

    const classes = useStyles()

    return(
        <Grid container xl={4} lg={6} md={6} xs={10} sm={8} className={classes.footer}>
            {
                quickLinks!! &&
                <Grid container style={{padding: '16px'}} justifyContent={"space-between"}>
                    <Grid item xs={12}>
                        <Link variant='subtitle1' component='a' href='about' style={{textDecoration: "underline"}}>About</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link variant='subtitle1' component='a' href='privacy-policy' style={{textDecoration: "underline"}}>Privacy Policy</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link variant='subtitle1' component='a' href='faq' style={{textDecoration: "underline"}}>FAQs</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link variant='subtitle1' component='a' href='contact' style={{textDecoration: "underline"}}>Contact</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link variant='subtitle1' component='a' href='terms-of-service' style={{textDecoration: "underline"}}>Terms of Service</Link>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} className={classes.copyRight}>
                <Box p={2}>
                    <Grid container>
                        <Grid container item xs={12} justifyContent={"center"}>
                            <Link component={"a"} color={"inherit"} href={PLAY_STORE_LINK} target={"_blank"}>Create Your Account</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )

}

export default Footer