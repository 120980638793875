import axios from "axios";
import {ApiResponse} from "../codelibrary/Models/ApiResponse";
import {UserPublic} from "../codelibrary/Models/UserPublic";

export const getUserInfo = async () =>
    await axios({
        url: process.env.REACT_APP_USER_INFO_URL
    })
        .then((res) => res)
        .catch((err) => {
            throw err
        })

export const getUserPublicProfile = async (userId: string) =>
    await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/user/${userId}`
    })
        .then((res) => {
            const data = new ApiResponse()
            data.status = res.status
            data.data = res.data
            return data
        })
        .catch((err) => {
            const data = new ApiResponse()
            data.status = err.response.status
            data.error = err.response.data.msg
            data.msg = err.response.data.msg
            throw data
        })

export const sendMessage = async (userId: string, message: string, userInfo: any, profile: UserPublic) => {
    return await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/${userId}/message`,
        headers: {
            'Content-Type': 'application/json',
            'x-user-info': userInfo
        },
        data: JSON.stringify({
            message: message,
            id: profile.id,
            username: profile.username,
            version: profile.version
        })
    })
        .then((res) => {
            const data = new ApiResponse()
            data.status = res.status
            data.data = res.data
            data.msg = res.data.msg
            return res
        })
        .catch((err) => {
            const data = new ApiResponse()
            data.status = err.response.status
            data.error = err.response.data.msg
            data.msg = err.response.data.msg
            throw data
        })
}

export const pingifyMessage = (publicId: string) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_PINGIFY_URL}/ping`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
            public_id: publicId
        })
    })
        .then((res) => {
            return res.data
        })
        .then(data => {})
        .catch((err) => {})
}
