import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material/styles";
import {createTheme} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

const theme = createTheme({
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.5,
            letterSpacing: "0.01071em",
        },
        body1: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        caption: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        button: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
        },
        h1: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1,
            letterSpacing: "-0.01562em"
        },
        h2: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.04,
            letterSpacing: "0em",
        },
        h4: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.17,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1.5rem",
            lineHeight: 1.33,
            letterSpacing: "0em",
        },
        h6: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        overline: {
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
            useNextVariants: true,
        }
    },
    palette: {
        primary: {
            light: '#ed4b82',
            main: '#e91e63',
            dark: '#a31545',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5393ff',
            main: '#2979ff',
            dark: '#1c54b2',
            contrastText: '#000',
        },
        text: {
            primary: 'rgba(0,0,0,0.87)',
            secondary: 'rgba(0,0,0,0.6)',
            disabled: 'rgba(0,0,0,0.38)'
        }
    },
    shape: {
        borderRadius: 16
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "white"
                }
            }
        }
    },
    spacing: [0, 4, 8, 16, 32, 64],
})

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
