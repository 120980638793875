import React from 'react';
import './App.css';
import {
    AppBar,
    Box, Divider, Drawer, Grid,
    IconButton, List, ListItem, ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import Home from "./home/Home";
import {
    Route,
    Routes
} from 'react-router-dom';
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import About from "./about/About";
import Contact from "./contact/Contact";
import FAQ from "./faq/FAQ";
import UserPublicPage from "./user-public/UserPublicPage";
import TermsOfService from "./terms-of-service/TermsOfService";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: theme.palette.primary.main,
    },
    full_vh: {
        top: 0,
        bottom: 0,
        padding:0,
        margin:0,
        left:0,
        width: 100,
        height: 100,
        minHeight: 100,
    },
    footer: {
        color: theme.palette.primary.contrastText,
        '& a': {
            color: theme.palette.text.secondary,
        }
    },
    copyRight: {
        '& a': {
            color: theme.palette.primary.main,
        },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    appBar: {
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    logoutButton: {
        color: theme.palette.error.main,
    },
    cover: {
        backgroundImage: `url('/assets/img/bg.webp')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: ["center", "center"].join(',')
}
}))

type Anchor = 'left';

function App() {

    const classes = useStyles()

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: Anchor) => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button key='about' onClick={() => {
                    window.location.href = 'about'
                }}>
                    <ListItemText primary={"About"} />
                </ListItem>
                <ListItem button key='privacy-policy' onClick={() => {
                    window.location.href = 'privacy-policy'
                }}>
                    <ListItemText primary={"Privacy Policy"} />
                </ListItem>
                <ListItem button key='terms-of-service' onClick={() => {
                    window.location.href = 'terms-of-service'
                }}>
                    <ListItemText primary={"Terms of Service"} />
                </ListItem>
                <ListItem button key='contact' onClick={() => {
                    window.location.href = 'contact'
                }}>
                    <ListItemText primary={"Contact"} />
                </ListItem>
                <ListItem button key='faq' onClick={() => {
                    window.location.href = 'faq'
                }}>
                    <ListItemText primary={"FAQ"} />
                </ListItem>
            </List>
            <Divider />
        </div>
    );

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar} elevation={4}>
                <Grid container item xs={12} justifyContent={"space-between"}>
                    <Grid item xs={6}>
                        <Toolbar>
                        {(['left'] as Anchor[]).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ ml: 1 }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer>
                            </React.Fragment>
                        ))}
                        <Typography variant="h6" className={classes.title} onClick={() => {
                            window.location.href = '..'
                        }}>
                            HushUP
                        </Typography>
                    </Toolbar>
                    </Grid>
                </Grid>
            </AppBar>
            <Box>
                <Routes>
                        <Route path='/' element={
                            <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            className={classes.cover}
                            >
                                <Home />
                            </Box>
                        }/>
                        <Route path='/about' element={<About />}/>
                        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
                        <Route path='/contact' element={<Contact />}/>
                        <Route path='/faq' element={<FAQ />}/>
                        <Route path='/terms-of-service' element={<TermsOfService />}/>
                        <Route path='/:id' element={
                            <Box paddingTop='16px'>
                                <UserPublicPage />
                            </Box>
                        }/>
                    </Routes>
            </Box>
        </React.Fragment>
    );
}

export default App;
