import React from "react";
import {Card, CardContent, Divider, Grid, List, Typography} from "@mui/material";
import moment from "moment";

interface Props {
    messages: any[]
}

const PublicMessagesList: React.FC<Props> = ({messages, ...props}) => {

    return(
        <>
            {
                messages.sort((a, b) => +a.timestamp > +b.timestamp ? -1 : 1).map((value, index, array) => {
                    return <Card style={{marginBottom: '1em', width: '100%'}}>
                        <CardContent>
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{value.message}</Typography>
                                    </Grid>
                                    <Grid container item xs={12} justifyContent='flex-end'>
                                        <Typography variant="caption" gutterBottom>{moment(+value.timestamp).format('Do MMMM \'YY')}</Typography>
                                    </Grid>
                                    <Divider style={{width:'100%'}} />
                                    {
                                    value.replies &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" style={{marginLeft: '8px'}}>Replies</Typography>
                                        </Grid>
                                        <List component="div" style={{marginLeft: '8px'}}>
                                            {
                                                value.replies.sort((a: any, b: any) => +a.timestamp > +b.timestamp ? -1 : 1).map((rep: any, inx: number) => {
                                                    return <Grid container>
                                                        <Typography variant="subtitle2">{rep.message}</Typography>
                                                    </Grid>
                                                })
                                            }
                                        </List>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                        </Card>
                })
            }
        </>
    )
}

export default PublicMessagesList
